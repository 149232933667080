import { useState } from "react";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootStrapForm from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect } from "react";
import Select from "react-select";
import * as Actions from "../../redux/actions/jobAction";
import * as userActions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { EDIT_JOB_SUCCESS } from "../../constants/action-types";
import { request } from "../../services/http-service";
import { useAxios } from "../../api/useAxios";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import convertHtmlToPlainText from "../../utils/convertHtmlToPlainText";
import { toolbar } from "../../constants/wysiwyg";

const Form = ({
  jobData,
  jobActions,
  userActions,
  status,
  dropdownFilters,
}) => {
  const navigate = useNavigate();
  const { get } = useAxios();

  // job description
  const defaultContentJobDescription = ContentState.createFromText(
    jobData?.description ? convertHtmlToPlainText(jobData?.description) : ""
  );
  const defaultEditorStatejobDescription = EditorState.createWithContent(
    defaultContentJobDescription
  );
  const [jobDescription, setJobDescription] = useState(
    jobData?.description || ""
  );
  const [editorStateJobDescription, setEditorStateJobDescription] = useState(
    defaultEditorStatejobDescription
  );

  // duties
  const defaultContentDuties = ContentState.createFromText(
    jobData?.duties ? convertHtmlToPlainText(jobData?.duties) : ""
  );
  const defaultEditorStateDuties =
    EditorState.createWithContent(defaultContentDuties);
  const [duties, setDuties] = useState(jobData?.duties || "");
  const [editorStateDuties, setEditorStateDuties] = useState(
    defaultEditorStateDuties
  );

  // benefits
  const defaultContentBenefits = ContentState.createFromText(
    jobData?.benefits ? convertHtmlToPlainText(jobData?.benefits) : ""
  );
  const defaultEditorStateBenefits = EditorState.createWithContent(
    defaultContentBenefits
  );
  const [benefits, setBenefits] = useState(jobData?.benefits);
  const [editorStateBenefits, setEditorStateBenefits] = useState(
    defaultEditorStateBenefits
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedWorkMode, setSelectedWorkMode] = useState(
    jobData.workModeIdsString
  );
  const [selectedCountry, setSelectedCountry] = useState(jobData?.countryId);
  const [cities, setCities] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [jobType, setJobType] = useState(jobData.employmentTypes[0]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [employmentTypeDropdown, setEmploymentTypeDropdown] = useState();
  const [isRemotePosition, setIsRemotePosition] = useState(false);

  const handleJobDescriptionChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftjsToHtml(rawContentState);
    setJobDescription(markup);
    setEditorStateJobDescription(editorState);
  };
  const handleDutiesChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftjsToHtml(rawContentState);
    setDuties(markup);
    setEditorStateDuties(editorState);
  };
  const handleBenefitsChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftjsToHtml(rawContentState);
    setBenefits(markup);
    setEditorStateBenefits(editorState);
  };

  const [selectedEducationLevel, setSelectedEducationLevel] = useState(() =>
    jobData?.educationLevels?.map((educationLevel, i) => {
      return {
        label: educationLevel,
        value: jobData.educationLevelIds[i],
      };
    })
  );

  const [selectedCities, setSelectedCities] = useState(() =>
    jobData?.locations?.map((location, i) => {
      return {
        label: location,
        value: jobData?.locationIds[i],
      };
    })
  );
  {
    <Editor
      editorState={editorStateDuties}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleDutiesChange}
      placeholder="Fill in for job description"
    />;
  }

  useEffect(() => {
    jobData?.description &&
      !editorStateJobDescription &&
      handleJobDescriptionChange(jobData?.description);
  }, [editorStateJobDescription, jobData?.description]);

  useEffect(() => {
    jobData?.duties &&
      !editorStateDuties &&
      handleDutiesChange(jobData?.duties);
  }, [editorStateDuties, jobData?.duties]);

  useEffect(() => {
    jobData?.benefits &&
      !editorStateBenefits &&
      handleBenefitsChange(jobData?.benefits);
  }, [editorStateBenefits, jobData?.benefits]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleGetLocations = async (countryId) => {
    try {
      const countries = await request(
        `utility/data/locations/countries`,
        "get",
        "",
        false,
        null
      );

      if (countries?.locationsData?.length < 1) {
        setCountryOptions([]);
      } else {
        setCountryOptions(
          countries.locationsData.sort((a, b) => a.value.localeCompare(b.value))
        );
      }
    } catch (error) {
      throw new Error(error);
    }

    if (!countryId) return;

    try {
      const cities = await get(
        `utility/data/locations/cities/${countryId}`,
        {}
      );

      if (cities?.locationsData?.length < 1) {
        setCities([]);
        setCityOptions([]);
      } else {
        setCities(
          cities.locationsData.sort((a, b) => a.value.localeCompare(b.value))
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSetCitiesOptions = () => {
    if (cities?.length < 1) return;

    const citiesOptions = cities.map((city) => {
      return {
        label: city.value,
        value: city.id,
      };
    });

    setCityOptions(citiesOptions);
  };

  useEffect(() => {
    handleGetLocations(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    countryOptions.length < 1 && handleGetLocations();
  }, [countryOptions]);

  useEffect(() => {
    handleSetCitiesOptions();
  }, [cities]);

  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    handleCountryChange,
  });

  const handleEditJob = async (data) => {
    let createJobObj = { ...data };
    createJobObj.educationLevelIds = createJobObj.educationLevelIds.map((el) =>
      parseInt(el.value, 10)
    );

    createJobObj["salaryRangeIds"] = [data.salaryRangeIds];
    createJobObj["workModeIds"] = [Number(...data.workModeIds)];
    createJobObj["workingDayIds"] = [Number(...data.workingDayIds)];
    createJobObj["experienceLevelIds"] = [Number(data.experienceLevelIds)];
    createJobObj["employmentTypeIds"] = [data.employmentTypeIds];
    createJobObj["title"] = data.position;
    createJobObj["publishingStatus"] = "PUBLISHED";

    createJobObj["hiringManager"] = data.hiringManager;

    createJobObj["isPaidJob"] = true;
    createJobObj["job_type"] = jobType;
    createJobObj["duties"] = data.requirements;
    createJobObj["workLocationIds"] = isRemotePosition
      ? []
      : data.workLocationIds.map((l) => l.value);
    createJobObj["isPaidJob"] = dropdownFilters.employmentTypes.find(
      (et) => et.id == createJobObj.employmentTypeIds
    ).isPaidJob;
    createJobObj.jobId = jobData.jobId;
    createJobObj.description = jobDescription;
    createJobObj.duties = duties;
    createJobObj.benefits = benefits;

    const res = await jobActions.editJob(createJobObj);

    res?.status === "ok"
      ? navigate("/jobs", { replace: true })
      : setErrorMessage(res?.errorMessage);
  };

  const handleOnTypeChange = (selectedJobType) => {
    setJobType(selectedJobType);
  };

  const createJobOption = {
    job_type: { required: "Job Type is required" },
    position: { required: "Job Position is required" },
    hiringManager: { required: "Hiring Manager is required" },
    description: { required: "Description is required" },
    benefits: { required: "Benefits is required" },
    requirements: { required: "Eequirements is required" },
    salaryRangeIds: { required: "Salary Range is required" },
    workLocationIds: { required: "City is required" },
    workLocationIds: {
      required: !isRemotePosition ? "City is required" : false,
    },
    educationLevelIds: { required: "Education Level is required" },
    employmentTypeIds: { required: "Employment Type is required" },
    experienceLevelIds: { required: "Experience Level is required" },
  };

  useEffect(() => {
    if (status === EDIT_JOB_SUCCESS) {
      navigate("/jobs", { replace: true });
    }
    jobActions.emptyStatus();
  }, [status]);

  useEffect(() => {
    jobActions.getJobFilters();
    jobActions.getCountryDropdown();
    userActions.getUserTokens();
  }, []);

  useEffect(() => {
    if (dropdownFilters) {
      jobData.employmentTypes[0] === "Internship"
        ? setEmploymentTypeDropdown(
            dropdownFilters?.employmentTypes?.filter(
              (et) => et.value === "Internship"
            )
          )
        : setEmploymentTypeDropdown(
            dropdownFilters?.employmentTypes?.filter(
              (et) => et.value !== "Internship"
            )
          );
    }
  }, [dropdownFilters]);

  const eduOptions = [];
  dropdownFilters?.educationLevels?.length > 0 &&
    dropdownFilters.educationLevels
      .filter((el) => (jobType === "Internship" ? el.value !== "Master" : el))
      .map((resp) => {
        let k = {};
        k.value = +resp.id;
        k.label = resp.value;
        eduOptions.push(k);
      });

  useEffect(() => {
    handleSetCitiesOptions();
  }, [cities]);

  useEffect(() => {
    if (jobData.title && dropdownFilters) {
      reset({
        employmentTypeIds: jobData.employmentTypeIds[0],
        experienceLevelIds: jobData.experienceLevelIds[0],
        position: jobData.title,
        hiringManager: jobData.hiringManager,
        salaryRangeIds: jobData.salaryRangeId,
        workingDayIds: jobData.workingDayIds,
        description: jobData.description,
        requirements: jobData.duties,
        benefits: jobData.benefits,
        workLocationIds: jobData?.locations?.map((location, i) => {
          return {
            label: location,
            value: jobData.locationIds[i],
          };
        }),
        educationLevelIds: jobData?.educationLevels?.map(
          (educationLevel, i) => {
            return {
              label: educationLevel,
              value: jobData.educationLevelIds[i],
            };
          }
        ),
      });

      !selectedEducationLevel &&
        setSelectedEducationLevel(() =>
          jobData.educationLevelIds.map((educationLevelIds, i) => {
            return {
              label: jobData.educationLevels[i],
              value: educationLevelIds,
            };
          })
        );
    }
  }, [jobData, dropdownFilters]);

  useEffect(() => {
    setIsRemotePosition(selectedWorkMode === "3");
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleEditJob)}
      id="edit-job-form"
      className="my-5"
    >
      <input name="version" value={formFields.selector} type="hidden" />
      <Row>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the employment type
          </label>
          <select
            {...register(
              "employmentTypeIds",
              createJobOption.employmentTypeIds
            )}
            className={`form-control ${!errors.employmentTypeIds && "mb-4"}`}
            id="employment_type"
            defaultValue={jobData.employmentType}
            onChange={({ currentTarget: { options, selectedIndex } }) =>
              handleOnTypeChange(options[selectedIndex].innerHTML)
            }
          >
            <option value="" disabled>
              Select the employment type
            </option>
            {employmentTypeDropdown &&
              employmentTypeDropdown.map((resp, index) => (
                <option key={index} value={+resp.id}>
                  {resp.value}
                </option>
              ))}
          </select>
          {errors.employmentTypeIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.employmentTypeIds?.message}
            </div>
          )}
        </Col>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Position/Role
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Position/Role"
            id="position"
            {...register("position", createJobOption.position)}
          />
          {errors.position && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.position?.message}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the salary range
          </label>
          <select
            className={`form-control ${!errors.salaryRangeIds && "mb-4"}`}
            id="salary_range"
            {...register("salaryRangeIds", createJobOption.salaryRangeIds)}
          >
            <option value="" disabled>
              Select the salary range
            </option>
            {dropdownFilters?.salaryRanges?.length > 0 &&
              dropdownFilters.salaryRanges
                .filter((sr) =>
                  jobType === "Internship"
                    ? sr.value === "Up to MYR 499" || sr.value === "MYR 500 to MYR 1199"
                    : sr
                )
                .map((resp, index) => (
                  <option key={index} value={+resp.id}>
                    {resp.value}
                  </option>
                ))}
          </select>
          {errors.salaryRangeIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.salaryRangeIds?.message}
            </div>
          )}
        </Col>

        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the work mode
          </label>
          <select
            {...register("workModeIds", createJobOption.workModeIds)}
            className={`form-control ${!errors.workModeIds && "mb-4"}`}
            id="work_mode"
            value={selectedWorkMode}
            onChange={({ currentTarget: { value } }) => {
              setSelectedWorkMode(value);
              setIsRemotePosition(value === "3");
            }}
          >
            <option value="" disabled>
              Select the work mode
            </option>
            {dropdownFilters?.workModes?.length > 0 &&
              dropdownFilters.workModes.map((resp, index) => (
                <option key={index} value={+resp.id}>
                  {resp.value}
                </option>
              ))}
          </select>
          {errors.workModeIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.workModeIds?.message}
            </div>
          )}
        </Col>

        {countryOptions?.length > 0 && (
          <Col xs={12} sm={6}>
            <label className="font--inter-semiBold text-black font-16 mb-2">
              Country
            </label>
            <Controller
              control={control}
              render={({ field: { onChange } }) => (
                <select
                  className={`form-control ${!errors.workCountryIds && "mb-4"}`}
                  value={selectedCountry}
                  defaultValue=""
                  id="country"
                  name="workCountryIds"
                  onChange={({ currentTarget: { value } }) => {
                    setSelectedCities([]);
                    setSelectedCountry(value);
                    onChange(value);
                  }}
                >
                  <option value="" disabled>
                    Country
                  </option>
                  {dropdownFilters?.countries?.length > 0 &&
                    dropdownFilters.countries.map((resp, index) => (
                      <option key={index} value={resp.id}>
                        {resp.value}
                      </option>
                    ))}
                </select>
              )}
              name="workCountryIds"
              {...register("workCountryIds", createJobOption.workCountryIds)}
            />
            {errors.workCountryIds && (
              <div className="text-danger font-14 mt-2 mb-4 mx-1">
                {errors.workCountryIds?.message}
              </div>
            )}
          </Col>
        )}

        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select City Locations
          </label>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                className={`form-control ${!errors.workLocationIds && "mb-4"}`}
                isMulti
                id="location"
                name="workLocationIds"
                value={selectedCities}
                placeholder={"Select City Locations"}
                options={cityOptions}
                onChange={(selectedOption) => {
                  setSelectedCities(selectedOption.value);
                  onChange(selectedOption);
                }}
              />
            )}
            name="selectedCities"
            {...register("workLocationIds", createJobOption.workLocationIds)}
          />
          {errors.workLocationIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.workLocationIds?.message}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the working days
          </label>
          <select
            {...register("workingDayIds", createJobOption.workingDayIds)}
            className={`form-control ${!errors.workingDayIds && "mb-4"}`}
            id="working_hours"
            defaultValue={jobData.workingDayId}
          >
            <option value="" disabled>
              Select the working days
            </option>
            {dropdownFilters?.workingDays?.length > 0 &&
              dropdownFilters.workingDays.map((resp, index) => (
                <option key={index} value={+resp.id}>
                  {resp.value}
                </option>
              ))}
          </select>
          {errors.workingDayIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.workingDayIds?.message}
            </div>
          )}
        </Col>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the educational level
          </label>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                className={`form-control ${
                  !errors.educationLevelIds && "mb-4"
                }`}
                isMulti
                value={selectedEducationLevel}
                id="educational_level"
                name="educationLevelIds"
                placeholder="Select the educational level"
                options={eduOptions}
                onChange={(selectedOption) => {
                  setSelectedEducationLevel(selectedOption.value);
                  onChange(selectedOption);
                }}
              />
            )}
            name="selectedEducationLevel"
            {...register(
              "educationLevelIds",
              createJobOption.educationLevelIds
            )}
          />
          {errors.educationLevelIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.educationLevelIds?.message}
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Select the experience level
          </label>
          <select
            {...register(
              "experienceLevelIds",
              createJobOption.experienceLevelIds
            )}
            className={`form-control ${!errors.experienceLevelIds && "mb-4"}`}
            id="working_hours"
            defaultValue=""
          >
            <option value="" disabled>
              Select the experience level
            </option>
            {dropdownFilters?.experienceLevels?.length > 0 &&
              dropdownFilters.experienceLevels.map((resp, index) => (
                <option key={index} value={+resp.id}>
                  {resp.value}
                </option>
              ))}
          </select>
          {errors.experienceLevelIds && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.experienceLevelIds?.message}
            </div>
          )}
        </Col>

        <Col xs={12} sm={6}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Hiring Manager
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Name of Hiring Manager"
            id="hiringManager"
            {...register("hiringManager", createJobOption.hiringManager)}
          />
          {errors.hiringManager && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.hiringManager?.message}
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Fill in for job description
          </label>
          <Editor
            editorState={editorStateJobDescription}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleJobDescriptionChange}
            placeholder="Fill in for job description"
            toolbar={toolbar}
            handlePastedText={() => false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Fill in job requirements and/or any additional skills required
          </label>
          <Editor
            editorState={editorStateDuties}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleDutiesChange}
            placeholder="Fill in job requirements and/or any additional skills required"
            toolbar={toolbar}
            handlePastedText={() => false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label className="font--inter-semiBold text-black font-16 mb-2">
            Perks – are there specific benefits to this role or general company
            benefits? Let candidates know here
          </label>
          <Editor
            editorState={editorStateBenefits}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleBenefitsChange}
            placeholder="Perks – are there specific benefits to this role or general company benefits? Let candidates know here"
            toolbar={toolbar}
            handlePastedText={() => false}
          />
          {errors.benefits && (
            <div className="text-danger font-14 mt-2 mb-4 mx-1">
              {errors.benefits?.message}
            </div>
          )}
        </Col>
      </Row>
      <div className="text-center text-danger --text-14">{errorMessage}</div>
      <Row>
        <Col className="text-end">
          <button
            type="submit"
            className={
              "text-decoration-none bg-success px-3 py-2 text-white rounded font--inter-semiBold border-0"
            }
          >
            Save
          </button>
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  tokens: props.userReducer.tokens,
  status: props.jobReducer.status,
  dropdownFilters: props.jobReducer.dropdownFilters,
  props: parentProps,
});

const mapDispatchToProps = (dispatch) => ({
  jobActions: bindActionCreators(Actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
