import { forwardRef, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootStrapForm from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect } from "react";
import Select from "react-select";
import * as Actions from "../../redux/actions/jobAction";
import * as userActions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { CREATE_JOB_SUCCESS } from "../../constants/action-types";
import { request } from "../../services/http-service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import convertHtmlToPlainText from "../../utils/convertHtmlToPlainText";
import { toolbar } from "../../constants/wysiwyg";
import Loader from "../Loader";

const ConnectedForm = forwardRef(
  ({ tokens, jobActions, userActions, status, dropdownFilters }, ref) => {
    const queryParams = new URLSearchParams(window.location.search);
    const isPremium = queryParams.get("premium") === "true";

    const [isSaveAble, setIsSaveAble] = useState(false);

    const navigate = useNavigate();
    const { job_type } = useParams();

    // job description
    const defaultContentJobDescription = ContentState.createFromText("");
    const defaultEditorStatejobDescription = EditorState.createWithContent(
      defaultContentJobDescription
    );
    const [jobDescription, setJobDescription] = useState();
    const [editorStateJobDescription, setEditorStateJobDescription] = useState(
      defaultEditorStatejobDescription
    );

    // duties
    const defaultContentDuties = ContentState.createFromText("");
    const defaultEditorStateDuties =
      EditorState.createWithContent(defaultContentDuties);
    const [duties, setDuties] = useState();
    const [editorStateDuties, setEditorStateDuties] = useState(
      defaultEditorStateDuties
    );

    // benefits
    const defaultContentBenefits = ContentState.createFromText("");
    const defaultEditorStateBenefits = EditorState.createWithContent(
      defaultContentBenefits
    );
    const [benefits, setBenefits] = useState();
    const [editorStateBenefits, setEditorStateBenefits] = useState(
      defaultEditorStateBenefits
    );

    const [jobType, setJobType] = useState(job_type);
    const [selectedCountry, setSelectedCountry] = useState();
    const [cities, setCities] = useState([]);
    const [isSSEnabled, setIsSSEnabled] = useState(isPremium || false);
    const [errorMessage, setErrorMessage] = useState("");
    const [formFields, setFormFields] = useState({});
    const [selectedEducationLevel, setSelectedEducationLevel] = useState();
    const [selectedCities, setSelectedCities] = useState();
    const [countryOptions, setCountryOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [employmentTypeDropdown, setEmploymentTypeDropdown] = useState();
    const [isRemotePosition, setIsRemotePosition] = useState(false);
    const [isDataComplete, setIsDataComplete] = useState(false);

    const handleJobDescriptionChange = (editorState) => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftjsToHtml(rawContentState);
      setJobDescription(markup);
      setEditorStateJobDescription(editorState);
    };
    const handleDutiesChange = (editorState) => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftjsToHtml(rawContentState);
      setDuties(markup);
      setEditorStateDuties(editorState);
    };
    const handleBenefitsChange = (editorState) => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftjsToHtml(rawContentState);
      setBenefits(markup);
      setEditorStateBenefits(editorState);
    };

    const handleCountryChange = (value) => {
      setSelectedCountry(value);
    };

    const handleGetLocations = async (countryId) => {
      try {
        const countries = await request(
          `utility/data/locations/countries`,
          "get",
          "",
          false,
          null
        );

        if (countries?.locationsData?.length < 1) {
          setCountryOptions([]);
        } else {
          setCountryOptions(
            countries.locationsData.sort((a, b) =>
              a.value.localeCompare(b.value)
            )
          );
        }
      } catch (error) {
        throw new Error(error);
      }

      if (!countryId) return;

      try {
        const cities = await request(
          `utility/data/locations/cities/${countryId}`,
          "get",
          "",
          false,
          null
        );

        if (cities?.locationsData?.length < 1) {
          setCities([]);
          setCityOptions([]);
        } else {
          setCities(
            cities.locationsData.sort((a, b) => a.value.localeCompare(b.value))
          );
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    const handleSetCitiesOptions = () => {
      if (cities?.length < 1) return;

      const citiesOptions = cities.map((city) => {
        return {
          label: city.value,
          value: city.id,
        };
      });

      setCityOptions(citiesOptions);
    };

    useEffect(() => {
      handleGetLocations(selectedCountry);
    }, [selectedCountry]);

    useEffect(() => {
      handleSetCitiesOptions();
    }, [cities]);

    const {
      reset,
      watch,
      register,
      getValues,
      control,
      formState: { errors },
      handleSubmit,
    } = useForm({
      handleCountryChange,
    });

    const formData = watch();

    const handleCreateJob = (data) => {
      setErrorMessage("");

      if (
        ((!isSSEnabled && tokens?.balance?.gold < 1) ||
          (isSSEnabled && tokens?.balance?.diamond < 1)) &&
        jobType !== "Internship"
      ) {
        setErrorMessage(
          "Sorry you dont have job token available. Please purchase token first"
        );
        return;
      }

      let createJobObj = { ...data };
      createJobObj.educationLevelIds = createJobObj.educationLevelIds.map(
        (el) => parseInt(el.value, 10)
      );

      createJobObj["salaryRangeIds"] = [Number(...data.salaryRangeIds)];
      createJobObj["workModeIds"] = [Number(...data.workModeIds)];
      createJobObj["workingDayIds"] = [Number(...data.workingDayIds)];
      createJobObj["experienceLevelIds"] = [Number(...data.experienceLevelIds)];
      createJobObj["employmentTypeIds"] = [Number(...data.employmentTypeIds)];
      createJobObj["title"] = data.position;
      createJobObj["publishingStatus"] = "PUBLISHED";

      createJobObj["hiringManager"] = data.hiringManager;

      createJobObj["isPaidJob"] = dropdownFilters.employmentTypes.find(
        (et) => et.id == createJobObj.employmentTypeIds
      ).isPaidJob;
      createJobObj["job_type"] = jobType;
      createJobObj["duties"] = data.requirements;
      createJobObj["workLocationIds"] = isRemotePosition
        ? []
        : data.workLocationIds.map((l) => l.value);
      createJobObj["tokenId"] = isSSEnabled ? 2 : 1;
      createJobObj.description = jobDescription;
      createJobObj.duties = duties;
      createJobObj.benefits = benefits;

      jobActions.createJob(createJobObj);
    };

    const handleOnTypeChange = (selectedJobType) => {
      setJobType(selectedJobType);
    };

    const createJobOption = {
      job_type: { required: "Job Type is required" },
      position: { required: "Job Position is required" },
      hiringManager: { required: "Hiring Manager is required" },
      description: { required: "Description is required" },
      benefits: { required: "Benefits is required" },
      requirements: { required: "Requirements is required" },
      salaryRangeIds: { required: "Salary Range is required" },
      workLocationIds: {
        required: !isRemotePosition ? "City is required" : false,
      },
      workingDayIds: { required: "Work Day is required" },
      educationLevelIds: { required: "Education Level is required" },
      employmentTypeIds: { required: "Employment Type is required" },
      experienceLevelIds: { required: "Experience Level is required" },
    };

    useEffect(() => {
      if (status === CREATE_JOB_SUCCESS) {
        navigate("/jobs", { replace: true });
      }
      jobActions.emptyStatus();
    }, [status]);

    useEffect(() => {
      jobActions.getJobFilters();
      jobActions.getCountryDropdown();
      userActions.getUserTokens();
    }, []);

    useEffect(() => {
      if (dropdownFilters) {
        if (job_type) {
          job_type === "internship"
            ? setEmploymentTypeDropdown(
                dropdownFilters?.employmentTypes?.filter(
                  (et) => et.value === "Internship"
                )
              )
            : setEmploymentTypeDropdown(
                dropdownFilters?.employmentTypes?.filter(
                  (et) => et.value !== "Internship"
                )
              );
        } else {
          setEmploymentTypeDropdown(dropdownFilters?.employmentTypes);
        }
      }
    }, [dropdownFilters]);

    const eduOptions = [];
    dropdownFilters?.educationLevels?.length > 0 &&
      dropdownFilters.educationLevels
        .filter((el) => (jobType === "Internship" ? el.value !== "Master" : el))
        .map((resp) => {
          let k = {};
          k.value = +resp.id;
          k.label = resp.value;
          eduOptions.push(k);
        });

    useEffect(() => {
      countryOptions.length < 1 && handleGetLocations();
    }, [countryOptions]);

    useEffect(() => {
      handleSetCitiesOptions();
    }, [cities]);

    useEffect(() => {
      if (dropdownFilters) {
        reset({
          description: "",
          requirements: "",
          benefits: "",
        });
      }
    }, [dropdownFilters]);

    useEffect(() => {
      const formValues = getValues();

      const filledField = Object.keys(formValues).filter(
        (key) => formValues[key]?.length
      );

      if (filledField?.length >= 9) {
        setIsSaveAble(true);
      } else {
        setIsSaveAble(false);
      }
    }, [formData]);

    // render

    useEffect(() => {
      if (
        tokens?.balance === 0 ||
        (job_type !== "internship" &&
          tokens?.balance?.gold < 1 &&
          tokens?.balance?.diamond < 1)
      ) {
        navigate("/billing", {
          replace: true,
          state: {
            isInsufficientToken: true,
            isRedirected: true,
          },
        });
      }
    }, [tokens, job_type]);

    const containerRef = useRef(null);

    useEffect(() => {
      containerRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, [containerRef]);

    if (!tokens?.balance) return <Loader isLoading={true} />;

    return (
      <>
        <Row
          ref={containerRef}
          className="justify-content-between align-items-center my-4"
        >
          <h4 className="font--inter-semiBold font-24 mb-2">Job Details</h4>
          <Col xs={12}>
            <div className="d-flex flex-row align-items-center container-token-details">
              <Col xs={9} className="d-flex align-items-center">
                <img
                  className="w-50px"
                  src={
                    !isSSEnabled
                      ? "/assets/icons/tokens/token-basic-sm.png"
                      : "/assets/icons/tokens/token-premium-sm.png"
                  }
                  alt="token"
                />
                <div>
                  <span className="--text-navy d-inline-block mx-3 --text-20 font--inter-semiBold">
                    This job will cost {jobType === "Internship" ? 0 : 1}{" "}
                    {isSSEnabled ? '"Premium"' : '"Basic"'} token
                  </span>
                  <br />
                  {!isSSEnabled && jobType !== "Internship" && (
                    <span className="--text-navy d-inline-block mx-3 font--inter-semiBold --text-12">
                      <span className="--text-16">
                        {tokens?.balance?.gold ?? 0}
                      </span>{" "}
                      Token balance
                    </span>
                  )}
                  {isSSEnabled && jobType !== "Internship" && (
                    <span className="--text-navy d-inline-block mx-3 font--inter-semiBold --text-12">
                      <span className="--text--16">
                        {tokens?.balance?.diamond ?? 0}
                      </span>{" "}
                      Token balance
                    </span>
                  )}
                </div>
              </Col>
              {jobType !== "Internship" && (
                <Col xs={3} className="d-flex justify-content-end">
                  <BootStrapForm.Check
                    inline
                    label={
                      !isSSEnabled ? "Switch to Premium" : "Switch to Basic"
                    }
                    className="me-1 outline-none"
                    type="switch"
                    name="search_and_shortlist"
                    id="search_and_shortlist"
                    checked={isSSEnabled}
                    onChange={() => setIsSSEnabled(!isSSEnabled)}
                  />
                </Col>
              )}
            </div>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(handleCreateJob)} id="create-job-form">
          <Row>
            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select the employment type
              </label>
              <select
                {...register(
                  "employmentTypeIds",
                  createJobOption.employmentTypeIds
                )}
                className={`form-control ${
                  !errors.employmentTypeIds && "mb-4"
                }`}
                id="employment_type"
                defaultValue={
                  employmentTypeDropdown && job_type === "internship"
                    ? employmentTypeDropdown[0].id
                    : ""
                }
                onChange={({ currentTarget: { options, selectedIndex } }) =>
                  handleOnTypeChange(options[selectedIndex].innerHTML)
                }
              >
                <option value="" disabled>
                  Select the employment type
                </option>
                {employmentTypeDropdown &&
                  employmentTypeDropdown.map((resp, index) => (
                    <option key={index} value={+resp.id}>
                      {resp.value}
                    </option>
                  ))}
              </select>
              {errors.employmentTypeIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.employmentTypeIds?.message}
                </div>
              )}
            </Col>
            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Position/Role
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Position/Role"
                id="position"
                {...register("position", createJobOption.position)}
              />
              {errors.position && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.position?.message}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select the salary range
              </label>
              <select
                className={`form-control ${!errors.salaryRangeIds && "mb-4"}`}
                id="salary_range"
                defaultValue=""
                {...register("salaryRangeIds", createJobOption.salaryRangeIds)}
              >
                <option value="" disabled>
                  Select the salary range
                </option>
                {dropdownFilters?.salaryRanges?.length > 0 &&
                  dropdownFilters.salaryRanges
                    .filter((sr) =>
                      jobType === "Internship"
                        ? sr.value === "Up to MYR 499" || sr.value === "MYR 500 to MYR 1199"
                        : sr
                    )
                    .map((resp, index) => (
                      <option key={index} value={+resp.id}>
                        {resp.value}
                      </option>
                    ))}
              </select>
              {errors.salaryRangeIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.salaryRangeIds?.message}
                </div>
              )}
            </Col>

            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select the work mode
              </label>
              <select
                {...register("workModeIds", createJobOption.workModeIds)}
                className={`form-control ${!errors.workModeIds && "mb-4"}`}
                id="work_mode"
                defaultValue=""
                onChange={({ currentTarget: { value } }) =>
                  setIsRemotePosition(value === "3")
                }
              >
                <option value="" disabled>
                  Select the work mode
                </option>
                {dropdownFilters?.workModes?.length > 0 &&
                  dropdownFilters.workModes.map((resp, index) => (
                    <option key={index} value={+resp.id}>
                      {resp.value}
                    </option>
                  ))}
              </select>
              {errors.workModeIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.workModeIds?.message}
                </div>
              )}
            </Col>

            {countryOptions?.length > 0 && (
              <Col xs={12} sm={6}>
                <label className="font--inter-semiBold text-black font-16 mb-2">
                  Country
                </label>
                <Controller
                  control={control}
                  render={({ field: { onChange } }) => (
                    <select
                      className={`form-control ${
                        !errors.workCountryIds && "mb-4"
                      }`}
                      value={selectedCountry}
                      defaultValue=""
                      id="country"
                      name="workCountryIds"
                      onChange={({ currentTarget: { value } }) => {
                        setSelectedCities([]);
                        setSelectedCountry(value);
                        onChange(value);
                      }}
                    >
                      <option value="" disabled>
                        Country
                      </option>
                      {countryOptions?.length > 0 &&
                        countryOptions.map((resp, index) => (
                          <option key={index} value={resp.id}>
                            {resp.value}
                          </option>
                        ))}
                    </select>
                  )}
                  name="workCountryIds"
                  {...register(
                    "workCountryIds",
                    createJobOption.workCountryIds
                  )}
                />
                {errors.workCountryIds && (
                  <div className="text-danger font-14 mt-2 mb-4 mx-1">
                    {errors.workCountryIds?.message}
                  </div>
                )}
              </Col>
            )}

            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select City Locations
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control ${
                      !errors.workLocationIds && "mb-4"
                    }`}
                    isMulti
                    defaultValue=""
                    value={selectedCities}
                    id="location"
                    name="workLocationIds"
                    placeholder="Select City Locations"
                    options={cityOptions}
                    onChange={(selectedOption) => {
                      setSelectedCities(selectedOption.value);
                      onChange(selectedOption);
                    }}
                  />
                )}
                name="selectedCities"
                {...register(
                  "workLocationIds",
                  createJobOption.workLocationIds
                )}
              />
              {errors.workLocationIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.workLocationIds?.message}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select the working days
              </label>
              <select
                {...register("workingDayIds", createJobOption.workingDayIds)}
                className={`form-control ${!errors.workingDayIds && "mb-4"}`}
                id="working_hours"
                defaultValue=""
              >
                <option value="" disabled>
                  Select the working days
                </option>
                {dropdownFilters?.workingDays?.length > 0 &&
                  dropdownFilters.workingDays.map((resp, index) => (
                    <option key={index} value={+resp.id}>
                      {resp.value}
                    </option>
                  ))}
              </select>
              {errors.workingDayIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.workingDayIds?.message}
                </div>
              )}
            </Col>
            {eduOptions && (
              <Col xs={12} sm={6}>
                <label className="font--inter-semiBold text-black font-16 mb-2">
                  Select the educational level
                </label>
                <Controller
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      className={`form-control edu-level ${
                        !errors.educationLevelIds && "mb-4"
                      }`}
                      isMulti
                      value={selectedEducationLevel}
                      id="educational_level"
                      name="educationLevelIds"
                      placeholder={"Select the educational level"}
                      options={eduOptions}
                      onChange={(selectedOption) => {
                        setSelectedEducationLevel(selectedOption.value);
                        onChange(selectedOption);
                      }}
                    />
                  )}
                  name="selectedEducationLevel"
                  {...register(
                    "educationLevelIds",
                    createJobOption.educationLevelIds
                  )}
                />
                {errors.educationLevelIds && (
                  <div className="text-danger font-14 mt-2 mb-4 mx-1">
                    {errors.educationLevelIds?.message}
                  </div>
                )}
              </Col>
            )}
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Select the experience level
              </label>
              <select
                {...register(
                  "experienceLevelIds",
                  createJobOption.experienceLevelIds
                )}
                className={`form-control ${
                  !errors.experienceLevelIds && "mb-4"
                }`}
                id="working_hours"
                defaultValue=""
              >
                <option value="" disabled>
                  Select the experience level
                </option>
                {dropdownFilters?.experienceLevels?.length > 0 &&
                  dropdownFilters.experienceLevels.map((resp, index) => (
                    <option key={index} value={+resp.id}>
                      {resp.value}
                    </option>
                  ))}
              </select>
              {errors.experienceLevelIds && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.experienceLevelIds?.message}
                </div>
              )}
            </Col>

            <Col xs={12} sm={6}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Hiring Manager
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Name of Hiring Manager"
                id="hiringManager"
                {...register("hiringManager", createJobOption.hiringManager)}
              />
              {errors.hiringManager && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.hiringManager?.message}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Fill in for job description
              </label>
              <Editor
                editorState={editorStateJobDescription}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName form-control"
                onEditorStateChange={handleJobDescriptionChange}
                placeholder="Fill in for job description"
                toolbar={toolbar}
                handlePastedText={() => false}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Fill in job requirements and/or any additional skills required
              </label>
              <Editor
                editorState={editorStateDuties}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleDutiesChange}
                placeholder="Fill in job requirements and/or any additional skills required"
                toolbar={toolbar}
                handlePastedText={() => false}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label className="font--inter-semiBold text-black font-16 mb-2">
                Perks – are there specific benefits to this role or general
                company benefits? Let candidates know here
              </label>
              <Editor
                editorState={editorStateBenefits}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleBenefitsChange}
                placeholder="Perks – are there specific benefits to this role or general company benefits? Let candidates know here"
                toolbar={toolbar}
                handlePastedText={() => false}
              />
            </Col>
          </Row>
          <div className="text-danger font-14">{errorMessage}</div>
          <Row>
            <Col className="text-end">
              <button
                id="btn-submit-job"
                type="submit"
                className={`${
                  isSaveAble ? "bg-success" : "bg-secondary"
                } text-decoration-none  px-3 py-2 text-white rounded font--inter-semiBold border-0`}
              >
                Save
              </button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
);

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  tokens: props.userReducer.tokens,
  status: props.jobReducer.status,
  dropdownFilters: props.jobReducer.dropdownFilters,
  props: parentProps,
});

const mapDispatchToProps = (dispatch) => ({
  jobActions: bindActionCreators(Actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

const Form = connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
export default forwardRef((props, ref) => <Form {...props} forwardRef={ref} />);
